body {
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
* {
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
button:focus,
button:active {
  outline: none;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal .modal-main {
  position: fixed;
  background: white;
  width: auto;
  height: auto;
  border-radius: 3px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
}
.modal .modal-main .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #C4C4C4;
}
.modal .modal-main .check {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
}
.modal .modal-main .check svg {
  width: 100px;
  height: 100px;
  display: block;
  margin: 40px auto 0;
  border: 15px solid #ADEAD7;
  border-radius: 50%;
  padding: 10px;
  animation: createBox 0.15s;
}
.modal .modal-main .check .reject {
  border: 15px solid #D37F7F;
}
.modal .modal-main .check .error {
  border: 15px solid #FEEC94;
}
.modal .modal-main .check .error .a {
  fill: #FFDB2B;
}
.modal .modal-main .check .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.modal .modal-main .check .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.modal .modal-main .check .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.modal .modal-main .check .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.modal .modal-main .check p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.modal .modal-main .check button {
  width: 147px;
  height: 36px;
  color: #ffffff;
  background: #6C63FF;
  border: 1px solid #5B60D3;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  border-radius: 3px;
  font-size: 14px;
  display: block;
  margin: auto;
}
.modal.display-block {
  display: flex;
}
.modal.display-none {
  display: none;
}
.enrollment-form {
  padding-top: 76px;
  position: relative;
}
.enrollment-form .form-header {
  background-color: #FFFFFF;
  height: 76px;
  box-shadow: 0px 3px 10px rgba(104, 104, 104, 0.07);
  padding: 0 62px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
.enrollment-form .form-header img {
  height: 46px;
  width: auto;
}
.enrollment-form .waves {
  width: 100%;
}
.enrollment-form .waves img {
  width: 100%;
}
.enrollment-form .title-div {
  display: flex;
  justify-content: center;
}
.enrollment-form .title-div h3 {
  font-size: 36px;
  color: #212B36;
  font-weight: bold;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .title-div h3 {
    font-size: 24px;
  }
}
.enrollment-form .form-container .form {
  padding: 0 25%;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollment-form .form-container .form {
    padding: 0 10%;
  }
}
.enrollment-form .form-container .form .input-div {
  padding: 12px 15px;
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 25px;
  transition: 0.25s ease-out;
  position: relative;
}
.enrollment-form .form-container .form .input-div .input-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  margin-bottom: -15px;
}
.enrollment-form .form-container .form .input-div select {
  border: none;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  padding: 11px 13px;
  font-size: 30px;
  background-color: transparent;
  margin-right: 15px;
  display: block;
  margin-top: 16px;
  margin-bottom: 15px;
}
.enrollment-form .form-container .form .input-div select:focus {
  outline: none;
}
.enrollment-form .form-container .form .input-div label {
  font-size: 14px;
  color: #212B36;
  position: relative;
}
.enrollment-form .form-container .form .input-div label input {
  width: 100%;
  border: none;
  font-size: 30px;
  margin-top: 10px;
  background-color: transparent;
}
.enrollment-form .form-container .form .input-div label input:focus {
  outline: none;
}
.enrollment-form .form-container .form .input-div label select {
  border: none;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  padding: 11px 13px;
  font-size: 30px;
  background-color: transparent;
  margin-right: 15px;
  display: block;
  margin-top: 16px;
}
.enrollment-form .form-container .form .input-div label select:focus {
  outline: none;
}
.enrollment-form .form-container .form .input-div label textarea {
  width: 100%;
  border: none;
  font-size: 30px;
  background-color: transparent;
}
.enrollment-form .form-container .form .input-div label textarea:focus {
  outline: none;
}
.enrollment-form .form-container .form .input-div label .input-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  margin-bottom: -15px;
}
.enrollment-form .form-container .form .input-div label .input-options select {
  margin-bottom: 15px;
}
.enrollment-form .form-container .form .input-div label .input-options .option {
  height: 48px;
  min-width: 68px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  padding: 11px 13px;
  font-size: 30px;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  margin-bottom: 15px;
}
.enrollment-form .form-container .form .input-div label .input-options .option i {
  display: none;
}
.enrollment-form .form-container .form .input-div label .input-options .option.selected {
  background: #6C63FF;
  color: #FFFFFF;
  transition: 0.25s ease-out;
}
.enrollment-form .form-container .form .input-div label .input-options .option.selected i {
  display: initial;
  margin-left: 15px;
}
.enrollment-form .form-container .form .input-div label .input-span {
  display: flex;
}
.enrollment-form .form-container .form .input-div label .input-span div {
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 30px;
  margin-top: 10px;
  color: #686868;
}
.enrollment-form .form-container .form .input-div label .input-span input {
  margin-left: 5px;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .form-container .form .input-div label .input-span div {
    font-size: 18px;
  }
}
.enrollment-form .form-container .form .input-div label .metric-span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  color: #686868;
}
.enrollment-form .form-container .form .input-div .error-span {
  display: none;
}
.enrollment-form .form-container .form .input-div .autocomplete {
  position: absolute;
  top: 75px;
  background-color: #FFFFFF;
  padding: 10px;
  left: 20px;
  right: 20px;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  z-index: 2;
}
.enrollment-form .form-container .form .input-div .autocomplete .autocomplete-item {
  font-size: 24px;
  color: rgba(113, 113, 113, 0.94);
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid rgba(219, 218, 227, 0.49);
  background-color: #FFFFFF;
}
.enrollment-form .form-container .form .input-div .autocomplete .autocomplete-item i {
  margin-right: 12px;
  color: rgba(113, 113, 113, 0.94);
}
.enrollment-form .form-container .form .input-div .autocomplete .autocomplete-item:first-child {
  border-top: initial;
}
.enrollment-form .form-container .form .input-div .autocomplete .bottom {
  height: 15px;
  margin-top: 10px;
}
.enrollment-form .form-container .form .input-div .autocomplete .bottom img {
  position: absolute;
  height: 15px;
  width: auto;
  right: 10px;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .form-container .form {
    padding: 0 5%;
  }
  .enrollment-form .form-container .form .input-div .input-options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    margin-bottom: -15px;
  }
  .enrollment-form .form-container .form .input-div select {
    padding: 11px 13px;
    font-size: 18px;
    margin: 0;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .enrollment-form .form-container .form .input-div select:focus {
    outline: none;
  }
  .enrollment-form .form-container .form .input-div label input {
    width: 100%;
    border: none;
    font-size: 18px;
    margin-top: 10px;
    padding-left: 0 !important;
    background-color: transparent;
  }
  .enrollment-form .form-container .form .input-div label input:focus {
    outline: none;
  }
  .enrollment-form .form-container .form .input-div label select {
    padding: 11px 13px;
    font-size: 18px;
  }
  .enrollment-form .form-container .form .input-div label select:focus {
    outline: none;
  }
  .enrollment-form .form-container .form .input-div label textarea {
    width: 100%;
    border: none;
    font-size: 18px;
    background-color: transparent;
  }
  .enrollment-form .form-container .form .input-div label textarea:focus {
    outline: none;
  }
  .enrollment-form .form-container .form .input-div label .input-options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    margin-bottom: -15px;
  }
  .enrollment-form .form-container .form .input-div label .input-options select {
    margin-bottom: 15px;
    margin-top: 0;
  }
  .enrollment-form .form-container .form .input-div label .input-options .option {
    height: 35px;
    min-width: 48px;
    margin-right: 15px;
    font-size: 18px;
  }
  .enrollment-form .form-container .form .input-div label .input-options .option i {
    display: none;
  }
  .enrollment-form .form-container .form .input-div label .input-options .option.selected {
    background: #6C63FF;
    color: #FFFFFF;
    transition: 0.25s ease-out;
  }
  .enrollment-form .form-container .form .input-div label .input-options .option.selected i {
    display: initial;
    margin-left: 15px;
  }
  .enrollment-form .form-container .form .input-div label .nmc-input {
    margin-left: 47px;
    width: 85%;
  }
  .enrollment-form .form-container .form .input-div label .nmc-span {
    top: 30.5px;
    left: 1px;
    font-size: 18px;
  }
  .enrollment-form .form-container .form .input-div label .metric-span {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 14px;
    color: #686868;
  }
  .enrollment-form .form-container .form .input-div .error-span {
    display: none;
  }
  .enrollment-form .form-container .form .input-div .autocomplete {
    left: 10px;
    right: 10px;
  }
  .enrollment-form .form-container .form .input-div .autocomplete .autocomplete-item {
    font-size: 16px;
  }
}
.enrollment-form .form-container .form .input-div.current-div {
  background: #FEFEFE;
  border: 1px solid #6C63FF;
}
.enrollment-form .form-container .form .input-div.input-error {
  background: #FFF3EF;
  border: 1px solid #BC3E06;
}
.enrollment-form .form-container .form .input-div.input-error .error-span {
  display: initial;
  position: absolute;
  top: 12px;
  font-size: 14px;
  right: 16px;
  color: #BC3E06;
}
.enrollment-form .form-container .submit-div {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 25%;
  background: #F9FAFB;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
  height: 109px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollment-form .form-container .submit-div {
    padding: 0 10%;
  }
}
.enrollment-form .form-container .submit-div button {
  background: #5D38DB;
  height: 59px;
  width: 203px;
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 2px;
  font-weight: bold;
}
.enrollment-form .form-container .submit-div button i {
  margin-left: 15px;
  color: #FFFFFF;
}
.enrollment-form .form-container .submit-div p {
  color: #BC3E06;
}
.enrollment-form .form-container .submit-div button:disabled {
  opacity: 0.5;
}
.confirm-div {
  min-width: 500px;
  background-color: #FFFFFF;
  padding: 54px 42px;
}
.confirm-div h4 {
  font-size: 30px;
  color: #212B36;
  margin: 0;
  margin-bottom: 5px;
}
.confirm-div p {
  font-size: 14px;
  color: #989898;
  margin: 0;
}
.confirm-div .input-div {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 44px;
  position: relative;
}
.confirm-div .input-div input {
  width: 59%;
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 30px;
  height: 59px;
  padding-right: 15px;
  padding-left: 15px;
}
.confirm-div .input-div input:focus {
  outline: none;
}
.confirm-div .input-div button {
  width: 39%;
  background: #5D38DB;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 30px;
  height: 59px;
  font-weight: bold;
  cursor: pointer;
}
.confirm-div .input-div button i {
  margin-left: 15px;
  color: #FFFFFF;
}
.confirm-div .input-div button:focus {
  outline: none;
}
.confirm-div .input-div .code-error {
  position: absolute;
  color: #BC3E06;
  font-size: 14px;
  top: 3px;
  left: 31%;
}
.confirm-div .input-div input.error {
  background: #FFF3EF;
  border: 1px solid #BC3E06;
}
.confirm-div h6 {
  font-size: 18px;
  color: #6C63FF;
  opacity: 0.5;
  text-align: center;
  margin: 0;
}
.success-div {
  min-width: 500px;
  background-color: #FFFFFF;
  padding: 54px 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
.success-div .ribbon-1 {
  position: absolute;
  top: 36px;
  left: 105px;
}
.success-div .ribbon-2 {
  position: absolute;
  top: 122px;
  left: 12px;
}
.success-div .ribbon-3 {
  position: absolute;
  top: 135px;
  left: 96px;
}
.success-div .ribbon-4 {
  position: absolute;
  top: 149px;
  right: 119px;
}
.success-div .ribbon-5 {
  position: absolute;
  top: 50px;
  right: 196px;
}
.success-div .checkmark {
  margin: 30px;
}
.success-div h4 {
  color: #686868;
  font-size: 20px;
}
.success-div p {
  font-size: 18px;
  color: #6C63FF;
  font-weight: bold;
  cursor: pointer;
}
.success-div button {
  background: #5D38DB;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 16px;
  height: 36px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0;
}
.form-error {
  min-width: 500px;
  background-color: #FFFFFF;
  padding: 54px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-error h3 {
  color: #686868;
  font-size: 16px;
  margin-bottom: 10px;
}
.form-error button {
  background: #5D38DB;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 16px;
  height: 36px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}
.form-error button:focus {
  outline: none;
}
.print-out {
  padding: 5%;
  max-width: 995px;
  display: none;
}
.print-out .form-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.print-out .form-top p {
  font-size: 18px;
  color: #0B0F14;
}
.print-out .form-body {
  margin-top: 30px;
  width: 100%;
}
.print-out .form-body table {
  width: 100%;
  border-collapse: collapse;
}
.print-out .form-body table tr td:first-child {
  width: 25%;
}
.print-out .form-body table tr td {
  border: 1px solid #919EAB;
  padding: 16px 12px;
}
.print-out .form-body table tr td h4 {
  font-size: 14px;
  color: #212B36;
  margin: 0;
}
.print-out .form-body table tr td h3 {
  font-size: 16px;
  color: #0B0F14;
  margin: 0;
}
.print-out .form-body table tr td div h4 {
  font-size: 12px;
  color: #212B36;
  margin: 0;
  margin-bottom: 5px;
}
.print-out .form-body table tr td div h3 {
  font-size: 16px;
  color: #0B0F14;
  margin: 0;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .success-div {
    display: none;
  }
  body {
    margin: 10mm 15mm 10mm 15mm !important;
  }
  .form-header {
    display: none !important;
  }
  .title-div {
    display: none !important;
  }
  .enrollment-form {
    padding-top: 0 !important;
  }
  .success-div {
    display: none;
  }
  .form-error {
    display: none;
  }
  .fa {
    display: none;
  }
  .form-header {
    display: none;
  }
  .title-div {
    display: none;
  }
  .waves {
    display: none;
  }
  .form-container {
    display: none;
  }
  .form-top {
    margin-top: 325px !important;
  }
  .form-top img {
    margin-bottom: 10px !important;
  }
  .form-body {
    margin-top: 20px !important;
  }
  td {
    padding: 14px 12px !important;
  }
  .print-out {
    padding: 0;
    display: initial;
    top: 0 !important;
  }
  .modal .modal-main {
    box-shadow: none !important;
  }
}
.dashboard-login {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #F5F6FA;
}
.dashboard-login div {
  flex-grow: 1;
  flex-basis: 0;
}
.dashboard-login .left {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-login .left img {
  height: 80px;
  width: auto;
}
.dashboard-login .right {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.dashboard-login .right .form {
  background: #FFFFFF;
  border-radius: 4px;
  max-width: 400px;
  padding: 50px;
  position: relative;
}
.dashboard-login .right .form h4 {
  font-size: 20px;
  text-align: center;
  color: #6C62E3;
  margin: 0;
  margin-bottom: 60px;
}
.dashboard-login .right .form p {
  position: absolute;
  top: 90px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 14px;
  color: #BC3E06;
}
.dashboard-login .right .form form .input-div {
  width: 100%;
  margin-bottom: 30px;
  height: 69px;
  background: rgba(237, 237, 237, 0.4);
  border: 0.75px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 7px 15px;
  position: relative;
}
.dashboard-login .right .form form .input-div label {
  width: 100%;
  font-size: 14px;
  color: #212B36;
  font-weight: medium;
}
.dashboard-login .right .form form .input-div input {
  width: 100%;
  font-size: 18px;
  color: #979797;
  background-color: transparent;
  border: none;
  padding-top: 9px;
}
.dashboard-login .right .form form .input-div input:focus {
  outline: none;
}
.dashboard-login .right .form form .input-div span {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 12px;
  color: #BC3E06;
}
.dashboard-login .right .form form .input-div.error {
  background: #FFF3EF;
  border: 1px solid #BC3E06;
}
.dashboard-login .right .form form button {
  width: 100%;
  height: 50px;
  background: #6C63FF;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.dashboard-login .right .form form button i {
  position: absolute;
}
.dashboard-login .right .form form button:active,
.dashboard-login .right .form form button:focus {
  outline: none;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .dashboard-login .left {
    display: none;
  }
  .dashboard-login .right .form img {
    margin-bottom: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 529px) {
  .dashboard-login .left {
    display: none;
  }
  .dashboard-login .right {
    padding: 0 20px;
  }
  .dashboard-login .right .form {
    padding: 50px 20px;
  }
  .dashboard-login .right .form img {
    margin-bottom: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
.no-display {
  display: none;
}
.network-status {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}
.network-status p {
  padding: 3px 10px;
  font-size: 12px;
  background-color: #FDF2ED;
}
.header {
  z-index: 5;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(104, 104, 104, 0.07);
  padding: 0 10%;
  box-sizing: border-box;
}
.header img {
  height: 35px;
  width: auto;
  margin-right: 200px;
}
.header img.mobile {
  display: none;
}
.header .menu-options {
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .menu-options h5 {
  margin: 0;
  font-size: 16px;
  color: #3A3643;
  opacity: 0.8;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  padding: 0 10px;
}
.header .menu-options h5.active {
  border-bottom: 4px solid #6C62E3;
}
.header .last {
  margin-left: auto;
}
.header .badge {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(0deg, #FFAF0E, #FFAF0E);
  color: #FFFFFF;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .header {
    justify-content: space-between;
  }
  .header img {
    margin-right: 0;
  }
  .header .badge {
    margin-left: initial;
  }
}
@media only screen and (max-width: 529px) {
  .header {
    height: 50px;
    padding: 0 5%;
    justify-content: space-between;
  }
  .header img {
    margin-right: 0;
  }
  .header img.desktop {
    display: none;
  }
  .header img.mobile {
    display: initial;
  }
  .header .menu-options {
    width: 170px;
  }
  .header .menu-options h5 {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
  }
  .header .last {
    margin-left: initial;
  }
}
.dashboard {
  width: 100%;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 60px;
  padding: 10%;
  padding-top: 40px;
}
@media only screen and (max-width: 529px) {
  .dashboard {
    margin-top: 50px;
    padding: 5%;
  }
}
.dashboard .overview {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 25px 40px;
  display: flex;
  margin-bottom: 25px;
}
.dashboard .overview .left h4 {
  font-size: 32px;
  color: #6C62E3;
  margin-bottom: 10px;
}
.dashboard .overview .left p {
  font-size: 20px;
  color: #979797;
  margin-bottom: 24px;
}
.dashboard .overview .left .enrollees-no {
  display: flex;
  align-items: center;
  background: #F5F6FA;
  height: 30px;
  border-radius: 20px;
  padding: 0 20px;
  margin-bottom: 12px;
}
.dashboard .overview .left .enrollees-no i {
  margin-right: 10px;
  color: #34C534;
  font-size: 14px;
}
.dashboard .overview .left .enrollees-no .bold {
  margin-right: 10px;
  font-weight: bold;
}
.dashboard .overview .left .enrollees-no p {
  font-size: 16px;
  margin-bottom: 0;
}
.dashboard .overview .left .enrollees-no p span {
  color: #3A3643;
  margin-right: 6px;
  font-weight: bold;
}
.dashboard .overview .right {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 50px;
}
.dashboard .overview .right .metric {
  height: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.dashboard .overview .right .metric h4 {
  font-size: 34px;
  color: #3A3643;
}
.dashboard .overview .right .metric p {
  font-size: 14px;
  color: #3A3643;
  opacity: 0.6;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .dashboard .overview {
    flex-wrap: wrap;
  }
  .dashboard .overview .right {
    padding-left: initial;
    justify-content: space-between;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 529px) {
  .dashboard .overview {
    padding: 20px;
    flex-wrap: wrap;
  }
  .dashboard .overview .left h4 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .dashboard .overview .left .enrollees-no i {
    font-size: 10px;
  }
  .dashboard .overview .left .enrollees-no p {
    font-size: 12px;
  }
  .dashboard .overview .right {
    padding-left: initial;
    justify-content: space-between;
    margin-top: 25px;
  }
  .dashboard .overview .right .metric {
    height: 100px;
  }
  .dashboard .overview .right .metric h4 {
    font-size: 24px;
  }
  .dashboard .overview .right .metric p {
    font-size: 12px;
  }
}
.dashboard .overview .loader-div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.dashboard .overview .loader-div i {
  font-size: 42px;
  color: #6C62E3;
}
.dashboard .alerts {
  width: 100%;
  min-height: auto;
  margin-top: 0;
  padding: 0;
  padding-top: 0;
  margin-bottom: 20px;
}
@media only screen and (max-width: 529px) {
  .dashboard .alerts {
    margin-top: 0;
    padding: 0;
  }
}
.dashboard .alerts .main {
  background: #ffffff;
  padding-bottom: 30px;
}
.dashboard .alerts .main .top {
  padding: 16px 30px;
  display: flex;
  align-items: center;
}
.dashboard .alerts .main .top h4 {
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #3A3643;
  margin-right: 36px;
}
.dashboard .alerts .main .alerts-content {
  padding: 0 30px;
}
.dashboard .alerts .main .alerts-content .alert {
  padding: 21px 0;
  display: flex;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.dashboard .alerts .main .alerts-content .alert .item {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
}
.dashboard .alerts .main .alerts-content .alert .item h6 {
  font-size: 12px;
  color: #605D68;
  font-weight: normal;
}
.dashboard .alerts .main .alerts-content .alert .item h5 {
  font-size: 14px;
  color: #3A3643;
  font-weight: normal;
}
.dashboard .alerts .main .alerts-content .alert .item h5 span {
  color: rgba(149, 149, 149, 0.5);
  margin-left: 10px;
  margin-right: 15px;
  font-size: 12px;
}
.dashboard .alerts .main .alerts-content .alert .item h5 span i {
  font-size: 6px;
  margin-right: 6px;
}
.dashboard .alerts .main .alerts-content .alert .item h5 .view-link {
  font-size: 12px;
  text-decoration-line: underline;
  color: #6C63FF;
  cursor: pointer;
}
.dashboard .alerts .main .alerts-content .alert .btn-div {
  margin-left: 27px;
  height: 40px;
  padding-top: 10px;
}
.dashboard .alerts .main .alerts-content .alert .btn-div button {
  width: 113px;
  height: 30px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
}
.dashboard .alerts .main .alerts-content .alert:last-child {
  border-bottom: none;
}
.alert-modal {
  background: #FFFFFF;
  border-radius: 4px;
  min-width: 545px;
  padding: 25px 30px;
}
.alert-modal .close {
  width: 108px;
  height: 42px;
  background: #EEEEEE;
  border: 1px solid #EEEEEE;
  cursor: pointer;
  border-radius: 4px;
}
.alert-modal .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.alert-modal h3 {
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #3A3643;
}
.alert-modal p {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.alert-modal p span {
  color: #F40000;
}
.alert-modal .modal-content {
  width: 100%;
  background: #F5F6FA;
  border-radius: 3px;
  padding: 16px 20px;
  margin-bottom: 35px;
  margin-top: 25px;
}
.alert-modal .modal-content .top {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #D8D8D8;
}
.alert-modal .modal-content .top div {
  width: 40%;
  font-size: 16px;
  line-height: 18px;
  color: #89868E;
}
.alert-modal .modal-content .top div:first-child,
.alert-modal .modal-content .top div:last-child {
  width: 30%;
}
.alert-modal .modal-content .item {
  display: flex;
  padding: 15px 0;
  border-bottom: 0.5px solid #D8D8D8;
}
.alert-modal .modal-content .item div {
  width: 40%;
  font-size: 16px;
  color: #3A3643;
}
.alert-modal .modal-content .item div:first-child,
.alert-modal .modal-content .item div:last-child {
  width: 30%;
}
.alert-modal .modal-content .item:last-child {
  border-bottom: 0;
}
.map-container {
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.map-container .top {
  padding: 0 40px;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}
.map-container .top h5 {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #3A3643;
  margin-bottom: 5px;
}
.map-container .top .section {
  display: flex;
  align-items: center;
}
.map-container .top .section .key {
  height: 31px;
  background: #F5F6FA;
  border-radius: 30px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.map-container .top .section .key .marker {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
  margin-right: 5px;
}
.map-container .top .section .key .marker i {
  font-size: 20px;
}
.map-container .top .section .key p {
  font-size: 12px;
  color: #7F7F7F;
}
.map-container .top .section .key:last-child {
  margin-right: 0;
}
.map-container .top h4 {
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #3A3643;
  margin-right: 21px;
}
.map-container .top .filter {
  padding: 0 18px;
  background: #F5F6FA;
  border-radius: 4px;
  font-size: 14px;
  color: #7F7F7F;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.map-container .top .filter i {
  margin-right: 11px;
}
.map-container .main {
  width: 100%;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
}
.map-container .main .filter-div {
  display: flex;
  padding: 10px 30px 30px;
  align-items: center;
}
.map-container .main .filter-div .filter-input {
  padding: 5px 15px 8px;
  height: 60px;
  background: #FEFEFE;
  border: 0.75px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 281px;
}
.map-container .main .filter-div .filter-input .input-div {
  position: relative;
}
.map-container .main .filter-div .filter-input .input-div .autocomplete {
  position: absolute;
  top: 25px;
  background-color: #FFFFFF;
  padding: 10px;
  left: -10px;
  right: -10px;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  z-index: 2;
}
.map-container .main .filter-div .filter-input .input-div .autocomplete .autocomplete-item {
  font-size: 14px;
  color: rgba(113, 113, 113, 0.94);
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid rgba(219, 218, 227, 0.49);
  background-color: #FFFFFF;
}
.map-container .main .filter-div .filter-input .input-div .autocomplete .autocomplete-item i {
  margin-right: 12px;
  color: rgba(113, 113, 113, 0.94);
}
.map-container .main .filter-div .filter-input .input-div .autocomplete .autocomplete-item:first-child {
  border-top: initial;
}
.map-container .main .filter-div .filter-input .input-div .autocomplete .bottom {
  height: 15px;
  margin-top: 10px;
}
.map-container .main .filter-div .filter-input .input-div .autocomplete .bottom img {
  position: absolute;
  height: 15px;
  width: auto;
  right: 10px;
}
.map-container .main .filter-div .filter-input label {
  font-size: 14px;
  color: #212B36;
}
.map-container .main .filter-div .filter-input input,
.map-container .main .filter-div .filter-input select {
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 0;
}
.map-container .main .filter-div .filter-input input {
  width: 100%;
}
.map-container .main .filter-div .filter-input select {
  max-width: 179px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
}
.map-container .main .filter-div .filter-input input:focus,
.map-container .main .filter-div .filter-input select:focus {
  outline: none;
}
.map-container .main .filter-div i {
  margin: 0 15px;
  color: #6C62E3;
}
.map-container .main .loader-div {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.map-container .main .loader-div i {
  font-size: 42px;
  color: #6C62E3;
}
.map-container .alert-popup {
  width: 200px;
}
.map-container .alert-popup .popup-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}
.map-container .alert-popup .popup-top p {
  margin-left: 7px;
  font-size: 12px;
  font-weight: bold;
}
.map-container .alert-popup .popup-top p.near-school {
  color: #CC4CCC;
}
.map-container .alert-popup .popup-top p.repeat-address {
  color: #FFAF0E;
}
.map-container .alert-popup .popup-top p.repeat-case {
  color: #32096D;
}
.map-container .alert-popup .popup-text {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #605D68;
}
.map-container .alert-popup table {
  margin-top: 15px;
  width: 100%;
}
.map-container .alert-popup table th {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  color: #979797;
  font-weight: normal;
}
.map-container .alert-popup table td {
  font-size: 12px;
}
.map-container .alert-popup table td:first-child {
  text-transform: capitalize;
}
.enrollees-container {
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
}
.enrollees-container .title {
  padding: 0 40px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.enrollees-container .title h4 {
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #3A3643;
}
.enrollees-container .title .input-div {
  width: 341px;
  height: 37px;
  position: relative;
}
.enrollees-container .title .input-div input {
  height: 100%;
  width: 100%;
  background: #FEFEFE;
  border: 0.75px solid #DBDAE3;
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 32px;
  font-size: 12px;
}
.enrollees-container .title .input-div input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #DBDAE3;
}
.enrollees-container .title .input-div input::-moz-placeholder {
  /* Firefox 19+ */
  color: #DBDAE3;
}
.enrollees-container .title .input-div input:-ms-input-placeholder {
  /* IE 10+ */
  color: #DBDAE3;
}
.enrollees-container .title .input-div input:-moz-placeholder {
  /* Firefox 18- */
  color: #DBDAE3;
}
.enrollees-container .title .input-div input:focus,
.enrollees-container .title .input-div input:active {
  outline: none;
}
.enrollees-container .title .input-div i {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #DADADA;
}
.enrollees-container .list {
  padding: 0 40px;
}
.enrollees-container .list table {
  width: 100%;
  border-spacing: 0;
}
.enrollees-container .list table tr th {
  padding: 20px 0 18px 0;
  text-align: left;
  border-bottom: 1px solid rgba(209, 216, 245, 0.6);
}
.enrollees-container .list table tr th div {
  font-size: 14px;
  color: #7F7F7F;
  font-weight: initial;
  padding: 0px 25px 0px 10px;
  background: #F5F6FA;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
.enrollees-container .list table tr th div i {
  margin-right: -15px;
  margin-left: 15px;
}
.enrollees-container .list table tr th div.first {
  cursor: initial;
}
.enrollees-container .list table tr th .sort-div {
  height: 30px;
}
.enrollees-container .list table tr th .sort-div .icon-div {
  margin-right: -15px;
  margin-left: 15px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.enrollees-container .list table tr th .sort-div .icon-div i {
  margin: 0;
  opacity: 0.5;
}
.enrollees-container .list table tr th .sort-div .icon-div i.active {
  opacity: initial;
}
.enrollees-container .list table tr th select {
  font-size: 14px;
  color: #7F7F7F;
  background: #F5F6FA;
  border-radius: 4px;
  height: 30px;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.enrollees-container .list table tr th select:focus,
.enrollees-container .list table tr th select:active {
  outline: none;
}
.enrollees-container .list table tr th select::-ms-expand {
  display: none;
}
.enrollees-container .list table tr td {
  padding: 18px 0 16px 0;
  border-bottom: 1px solid rgba(209, 216, 245, 0.6);
  font-size: 16px;
  color: #89868E;
}
.enrollees-container .list table tr td button {
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
  height: 35px;
  font-weight: bold;
}
.enrollees-container .list table tr td:last-child {
  text-align: right;
}
.enrollees-container .list table tr .loader {
  border-bottom: 0;
  text-align: center !important;
}
.enrollees-container .list table tr .loader i {
  color: #6C63FF;
  font-size: 46px;
}
.enrollees-container .list .btn-div {
  display: flex;
  justify-content: center;
  padding: 31px;
  box-sizing: border-box;
}
.enrollees-container .list .btn-div button {
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  width: 144px;
  height: 35px;
  font-weight: bold;
}
.enrollees-container .list .pagination-div {
  display: flex;
  justify-content: center;
  padding: 31px;
  box-sizing: border-box;
  position: relative;
}
.enrollees-container .list .pagination-div .entries {
  position: absolute;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  color: #7F7F7F;
  border: 1px solid rgba(209, 216, 245, 0.6);
  border-radius: 2px;
  box-sizing: border-box;
  left: 0;
}
.enrollees-container .list .pagination-div .entries select {
  font-size: 16px;
  color: #89868E;
  background: transparent;
  border: 0;
  padding: 0;
  margin-top: 3px;
}
.enrollees-container .list .pagination-div .entries select:focus,
.enrollees-container .list .pagination-div .entries select:active {
  outline: none;
}
.enrollees-container .list .pagination-div .pagination {
  display: flex;
  align-items: center;
}
.enrollees-container .list .pagination-div .pagination button {
  width: 86px;
  height: 42px;
  font-size: 16px;
  color: #6C62E3;
  border: 0.75px solid #E8EBF2;
  border-radius: 4px;
  background: #F5F6FA;
  font-weight: bold;
}
.enrollees-container .list .pagination-div .pagination button:first-child {
  margin-right: 7px;
}
.enrollees-container .list .pagination-div .pagination button:disabled {
  opacity: 0.5;
}
.enrollees-container .list .pagination-div .pagination input {
  width: 36px;
  height: 42px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.75px solid #E8EBF2;
  font-size: 16px;
  color: #6C63FF;
  margin-right: 7px;
}
.enrollees-container .list .pagination-div .pagination p {
  font-size: 16px;
  cursor: default;
  margin-right: 7px;
}
.enrollees-container .list .pagination-div .pagination div {
  width: 36px;
  height: 42px;
  background: #6C63FF;
  box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-right: 7px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollees-container .list table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .enrollees-container .list table tr td {
    padding: 15px 0;
    padding-right: 15px;
  }
  .enrollees-container .list .pagination-div {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  .enrollees-container .list .pagination-div .entries {
    position: initial;
  }
}
.enrollees {
  width: 100%;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 60px;
  padding: 10%;
  padding-top: 40px;
}
@media only screen and (max-width: 529px) {
  .enrollees {
    margin-top: 50px;
    padding: 5%;
  }
}
.enrollees .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
}
.enrollees .top h3 {
  font-size: 32px;
  color: #6C62E3;
}
.enrollees .top button {
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  width: 145px;
  height: 42px;
  color: #FFFFFF;
  font-size: 15px;
}
.enrollees .top button i {
  margin-left: 21px;
}
.enrollees .top .add-enrollee i {
  margin-left: 0;
}
.enrollees .top .dropdown {
  position: absolute;
  display: none;
  z-index: 2;
  top: 42px;
  right: 0;
  width: 145px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(104, 104, 104, 0.07);
  transition: 0.5s ease-in;
}
.enrollees .top .dropdown h4 {
  padding: 10px 20px;
  cursor: pointer;
}
.enrollees .top .dropdown h4:hover {
  background: #6C63FF;
  color: #FFFFFF;
}
.enrollees .top .dropdown.show {
  display: initial;
}
.alerts {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 60px;
  padding: 18%;
  padding-top: 40px;
}
@media only screen and (max-width: 529px) {
  .alerts {
    margin-top: 50px;
    padding: 5%;
  }
}
.alerts .main {
  background: #ffffff;
  padding-bottom: 30px;
}
.alerts .main .top {
  padding: 25px 30px;
  display: flex;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.alerts .main .top .tab {
  background: #F5F6FA;
  border-radius: 4px;
  height: 38px;
  min-width: 55px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #B6B6B6;
  margin-right: 15px;
  cursor: pointer;
}
.alerts .main .top .tab.active {
  background: #6C63FF;
  color: #FFFFFF;
  font-weight: bold;
}
.alerts .main .alerts-content {
  padding: 0 30px;
}
.alerts .main .alerts-content .date {
  padding-top: 24px;
  padding-left: 30px;
  padding-bottom: 12px;
  font-size: 14px;
  color: #605D68;
}
.alerts .main .alerts-content .alert {
  padding: 21px 0;
  display: flex;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.alerts .main .alerts-content .alert .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.alerts .main .alerts-content .alert .item {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
}
.alerts .main .alerts-content .alert .item h6 {
  font-size: 12px;
  color: #605D68;
  font-weight: normal;
}
.alerts .main .alerts-content .alert .item h6 span {
  color: #959595;
  margin-left: 10px;
}
.alerts .main .alerts-content .alert .item h5 {
  font-size: 14px;
  color: #605D68;
  font-weight: normal;
}
.alerts .main .alerts-content .alert .item .change {
  color: #FF5A00;
}
.alerts .main .alerts-content .alert .btn-div {
  margin-left: 27px;
  height: 40px;
  padding-top: 10px;
}
.alerts .main .alerts-content .alert .btn-div button {
  width: 113px;
  height: 30px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
}
.alerts .main .alerts-content .alert:last-child {
  border-bottom: none;
}
.enrollee-profile {
  width: 100%;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 60px;
  padding: 10%;
  padding-top: 40px;
}
@media only screen and (max-width: 529px) {
  .enrollee-profile {
    margin-top: 50px;
    padding: 5%;
  }
}
.enrollee-profile .name-div {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.enrollee-profile .name-div h4 {
  font-size: 32px;
  color: #6C62E3;
}
.enrollee-profile .name-div .status {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 16px;
  color: #FAFAFA;
  padding: 0 15px;
  margin-left: 22px;
  margin-right: 15px;
}
.enrollee-profile .name-div .status.positive {
  background: #E5454C;
}
.enrollee-profile .name-div .status.negative {
  background: #14DA9E;
}
.enrollee-profile .name-div .status.unverified {
  background: #6C62E3;
}
.enrollee-profile .name-div button {
  height: 30px;
  border: 0.75px solid #6C62E3;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  font-size: 14px;
  color: #6C62E3;
}
.enrollee-profile .name-div select:active,
.enrollee-profile .name-div select:focus {
  outline: none;
}
.enrollee-profile .map-container .main {
  display: block;
}
.enrollee-profile .main {
  display: flex;
}
.enrollee-profile .main .left {
  width: 70%;
  background-color: #FFFFFF;
  border-radius: 4px;
}
.enrollee-profile .main .left .top {
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
  padding: 24px 40px;
}
.enrollee-profile .main .left .top h5 {
  font-size: 18px;
  color: #3A3643;
}
.enrollee-profile .main .left .top .logo {
  display: none;
}
.enrollee-profile .main .left .details {
  padding: 40px 54px;
}
.enrollee-profile .main .left .details .loader {
  width: 100%;
  display: flex;
  justify-content: center;
}
.enrollee-profile .main .left .details .loader i {
  color: #6C63FF;
  font-size: 46px;
}
.enrollee-profile .main .left .details table {
  width: 100%;
  border-spacing: 0;
}
.enrollee-profile .main .left .details table tr td {
  border-bottom: 0.75px solid #E5E4E6;
  padding: 17px 0;
}
.enrollee-profile .main .left .details table tr td h3,
.enrollee-profile .main .left .details table tr td h4 {
  font-size: 12px;
  color: #212B36;
}
.enrollee-profile .main .left .details table tr td:first-child {
  width: 30%;
}
.enrollee-profile .main .left .details.edit table {
  width: 100%;
  border-spacing: 0;
}
.enrollee-profile .main .left .details.edit table tr td {
  border-bottom: 0;
  padding: 17px 0;
  position: relative;
}
.enrollee-profile .main .left .details.edit table tr td h3,
.enrollee-profile .main .left .details.edit table tr td h4 {
  font-size: 12px;
  color: #212B36;
}
.enrollee-profile .main .left .details.edit table tr td input,
.enrollee-profile .main .left .details.edit table tr td select {
  background: #FEFEFE;
  border: 0.75px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  height: 38px;
  font-size: 14px;
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
}
.enrollee-profile .main .left .details.edit table tr td input.error {
  border: 0.75px solid #BC3E06;
  background: #FFF3EF;
}
.enrollee-profile .main .left .details.edit table tr td .flex-div {
  display: flex;
  justify-content: space-between;
}
.enrollee-profile .main .left .details.edit table tr td .flex-div select:first-child {
  margin-right: 15px;
}
.enrollee-profile .main .left .details.edit table tr td .flex-div select:last-child {
  margin-left: 15px;
}
.enrollee-profile .main .left .details.edit table tr td div {
  position: relative;
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete {
  position: absolute;
  top: 45px;
  background-color: #FFFFFF;
  padding: 10px;
  left: 5px;
  right: 5px;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  z-index: 2;
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete .autocomplete-item {
  font-size: 14px;
  color: rgba(113, 113, 113, 0.94);
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid rgba(219, 218, 227, 0.49);
  background-color: #FFFFFF;
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete .autocomplete-item i {
  margin-right: 12px;
  color: rgba(113, 113, 113, 0.94);
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete .autocomplete-item:first-child {
  border-top: initial;
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete .bottom {
  height: 15px;
  margin-top: 10px;
}
.enrollee-profile .main .left .details.edit table tr td div .autocomplete .bottom img {
  position: absolute;
  height: 15px;
  width: auto;
  right: 10px;
}
.enrollee-profile .main .left .details.edit table tr td span {
  position: absolute;
  bottom: 0;
  left: 15px;
  font-size: 12px;
  color: #BC3E06;
}
.enrollee-profile .main .left .details.edit table tr td:first-child {
  width: 30%;
}
.enrollee-profile .main .left .details.edit .button-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  padding-top: 25px;
}
.enrollee-profile .main .left .details.edit .button-div p {
  color: #BC3E06;
  margin-right: 25px;
}
.enrollee-profile .main .left .details.edit .button-div button {
  height: 42px;
  width: 145px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
}
.enrollee-profile .main .left .no-border {
  background: #ffffff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.enrollee-profile .main .left .button-div {
  display: flex;
  justify-content: flex-end;
  padding: 0px 55px 25px;
}
.enrollee-profile .main .left .button-div button {
  height: 42px;
  width: 145px;
  background: #919EAB;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
}
.enrollee-profile .main .right {
  margin-left: 30px;
  width: 30%;
}
.enrollee-profile .main .right .closest {
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 28px;
  box-sizing: border-box;
}
.enrollee-profile .main .right .closest .item {
  display: flex;
}
.enrollee-profile .main .right .closest .item img {
  margin-right: 20px;
  height: 40px;
  width: 40px;
}
.enrollee-profile .main .right .closest .item h5 {
  color: #5B60D3;
  font-size: 12px;
  margin-bottom: 5px;
}
.enrollee-profile .main .right .closest .item h6 {
  font-size: 12px;
  color: #4D4D4D;
}
.enrollee-profile .main .right .closest .item:first-child {
  padding-bottom: 20px;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.enrollee-profile .main .right .closest .item:last-child {
  padding-top: 20px;
}
.enrollee-profile .main .right .btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.enrollee-profile .main .right .btn-div button {
  font-size: 14px;
  color: #FFFFFF;
  width: 234px;
  height: 42px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  font-weight: bold;
}
.change-status {
  padding: 40px 60px;
  min-width: 350px;
  position: relative;
}
.change-status h3 {
  font-size: 18px;
  color: #6C62E3;
  margin-bottom: 32px;
  text-align: center;
}
.change-status select,
.change-status input {
  height: 40px;
  width: 100%;
  border: 0.75px solid #6C62E3;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  font-size: 16px;
  color: #6C62E3;
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
}
.change-status select:focus,
.change-status input:focus {
  outline: none;
}
.change-status select:active,
.change-status input:active {
  outline: none;
}
.change-status h5 {
  color: #4D4D4D;
  font-size: 14px;
  margin-bottom: 10px;
}
.change-status p {
  font-size: 12px;
  text-align: center;
  color: #BC3E06;
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
}
.change-status button {
  font-size: 14px;
  color: #FFFFFF;
  width: 100%;
  height: 42px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  font-weight: bold;
}
.change-status button.cancel {
  background: transparent;
  color: #6C63FF;
  border: 1.5px solid #6C63FF;
  margin-top: 10px;
  position: absolute;
  width: auto;
  height: auto;
  top: 10px;
  right: 10px;
  padding: 2px;
  font-size: 12px;
  font-weight: bolder;
}
.change-status button:disabled {
  opacity: 0.5;
}
@media print {
  .header {
    display: none;
  }
  .map-container {
    display: none;
  }
  .employee-profile {
    width: 995px;
    padding: 0 !important;
    margin-top: 0 !important;
  }
  .name-div {
    display: none !important;
  }
  .left {
    width: 100% !important;
  }
  .right {
    display: none !important;
  }
  .top {
    padding: 0 !important;
    text-align: center !important;
    margin-bottom: 20px;
    border-bottom: none !important;
  }
  .logo {
    display: initial !important;
  }
  .button-div {
    display: none !important;
  }
  .details {
    width: 100%;
    padding: 0 !important;
  }
  table {
    width: 100%;
    border-spacing: 0;
  }
  td {
    border: 1px solid #919EAB !important;
    padding: 15px 12px !important;
  }
  .no-border {
    display: none;
  }
}
@page {
  size: A4;
  margin: 0;
}
.officer-profile {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 60px;
  padding: 10%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 529px) {
  .officer-profile {
    margin-top: 50px;
    padding: 5%;
  }
}
.officer-profile .profile-main {
  width: 733px;
  height: 100%;
  padding: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
}
.officer-profile .profile-main .badge {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  background: linear-gradient(0deg, #FFAF0E, #FFAF0E);
  color: #FFFFFF;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
}
.officer-profile .profile-main .last-login {
  display: inline-flex;
  align-items: center;
  background: #F5F6FA;
  height: 30px;
  border-radius: 20px;
  padding: 0 20px;
  margin-bottom: 43px;
}
.officer-profile .profile-main .last-login i {
  margin-right: 10px;
  color: #34C534;
  font-size: 14px;
}
.officer-profile .profile-main .last-login .bold {
  margin-right: 10px;
  font-weight: bold;
}
.officer-profile .profile-main .last-login p {
  font-size: 16px;
  margin-bottom: 0;
}
.officer-profile .profile-main button {
  width: 167px;
  height: 50px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  font-size: 18px;
  color: #FFFFFF;
}
.officer-profile .profile-main .change-password-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  background: #919EAB;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  width: 150px;
  height: 38px;
  font-size: 14px;
}
.officer-profile .change-password {
  background-color: #FFFFFF;
  padding: 43px 40px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.officer-profile .change-password h4 {
  font-size: 18px;
  color: #6C62E3;
  margin-bottom: 42px;
}
.officer-profile .change-password form .input-div {
  width: 100%;
  margin-bottom: 30px;
  height: 60px;
  background: rgba(237, 237, 237, 0.4);
  border: 0.75px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 15px;
}
.officer-profile .change-password form .input-div label {
  width: 100%;
  font-size: 14px;
  color: #212B36;
  font-weight: medium;
}
.officer-profile .change-password form .input-div input {
  width: 100%;
  font-size: 18px;
  color: #979797;
  background-color: transparent;
  border: none;
  padding-top: 4px;
}
.officer-profile .change-password form .input-div input:focus {
  outline: none;
}
.officer-profile .change-password form button {
  width: 100%;
  height: 50px;
  background: #6C63FF;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  cursor: pointer;
}
.officer-profile .change-password form button:active,
.officer-profile .change-password form button:focus {
  outline: none;
}
